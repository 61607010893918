import "./ucn.scss";
import InView from "in-view";
import "focus-visible";
import { bootstrapper } from "@common/bootstrapper/bootstrapper.js";
import { emitter } from "@common/utilities.js";

if (process.env.NODE_ENV !== "production") {
	console.log("Looks like we are in development mode!");
}

bootstrapper({
	chartBlock: "chart",
	tabs: "tabs",
	accordion: "accordion",
	timelineAccordion: "timeline-accordion",
	linkNavigation: "link-navigation",
	imageGallery: "image-gallery",
	carousel: "carousel",
	headerSmall: "header--small",
	headerNavigation: "header--navigation",
	headerGeneric: "body",
	headerFavorite: "js-header-favorite",
	hovernavigation: "hovernavigation",
	manualNews: "manual-news",
	videoPlaylist: "video-playlist",
	form: "umbraco-forms-kruso",
	menu: "menu-js",
	videoThumbs: "video-thumbs-js",
	registrationsForm: "js-registrations-form",
	introBox: "js-intro-box",
	videoTestimonial: "js-video-testimonial",
	toTop: "to-top",
	tableOfContent: "js-table-of-content",
});

document.addEventListener("DOMContentLoaded", function () {
	new InView(".inview-watch")
		.on("enter", (el) => {
			el.classList.add("inview--visible");
			el.classList.add("inview--was-visible");
		})
		.on("exit", (el) => {
			el.classList.remove("inview--visible");
		});

	new InView(".inview-watch-delayed")
		.on("enter", (el) => {
			el.classList.add("inview--visible");
			el.classList.add("inview-delayed--was-visible");
		})
		.on("exit", (el) => {
			el.classList.remove("inview--visible");
		});

	new InView(".inview-btn, .richtext .btn-primary-arrow")
		.on("enter", (el) => {
			el.classList.add("inview--visible");
			el.classList.add("inview--was-visible");
		})
		.on("exit", (el) => {
			el.classList.remove("inview--visible");
		});
	new InView(".inview-gradient")
		.on("enter", (el) => {
			el.classList.add("inview--visible");
			el.classList.add("inview--was-visible");
		})
		.on("exit", (el) => {
			el.classList.remove("inview--visible");
		});
});

(() => {
	const isSafari =
		navigator.userAgent.indexOf("Chrome") === -1 &&
		navigator.userAgent.indexOf("Safari") > -1;
	if (isSafari) {
		document.body.classList.add("safari");
	}

	const isFirefox = navigator.userAgent.indexOf("Firefox") > -1;
	if (isFirefox) {
		// UCN-620: firefox scrolls to bottom of page when anchors are in the url
		const elId = window.location.hash;
		if (elId.length > 1) {
			const el = document.getElementById(elId.substring(1));
			if (el) {
				document.body.classList.add("firefox-anchor-fade");
				document.body.classList.add("firefox-anchor-fade--hidden");
				// Scroll to anchor
				document.addEventListener("DOMContentLoaded", (event) => {
					el.scrollIntoView(true);
					document.body.classList.remove(
						"firefox-anchor-fade--hidden"
					);
				});
			}
		}
	}
})();
