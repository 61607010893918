const COMPONENT_CLASS = {
	chartBlock: "chart",
	tabs: "tabs",
	accordion: "accordion",
	timelineAccordion: "timeline-accordion",
	linkNavigation: "link-navigation",
	imageGallery: "image-gallery",
	carousel: "carousel",
	headerSmall: "header--small",
	headerNavigation: "header--navigation",
	headerGeneric: "body",
	headerFavorite: "js-header-favorite",
	manualNews: "manual-news",
	hovernavigation: "hovernavigation",
	videoPlaylist: "video-playlist",
	form: "umbraco-forms-kruso",
	menu: "menu-js",
	videoThumbs: "video-thumbs-js",
	registrationsForm: "js-registrations-form",
	introBox: "js-intro-box",
	videoTestimonial: "js-video-testimonial",
	toTop: "to-top",
	tableOfContent: "js-table-of-content",
};

export { COMPONENT_CLASS };
