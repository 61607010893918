/**
 * Creates a new instance of Emitter.
 * @class
 * @returns {Object} Returns a new instance of Emitter.
 * @example
 * // Creates a new instance of Emitter.
 * var Emitter = require('emitter');
 *
 * var emitter = new Emitter();
 */
class Emitter {
	/**
	 * Adds a listener to the collection for the specified event.
	 * @memberof! Emitter.prototype
	 * @function
	 * @param {String} event - The event name.
	 * @param {Function} listener - A listener function to add.
	 * @returns {Object} Returns an instance of Emitter.
	 * @example
	 * // Add an event listener to "foo" event.
	 * emitter.on('foo', listener);
	 */
	on(event, listener) {
		// Use the current collection or create it.
		this._eventCollection = this._eventCollection || {};

		// Use the current collection of an event or create it.
		this._eventCollection[event] = this._eventCollection[event] || [];

		// Appends the listener into the collection of the given event
		this._eventCollection[event].push(listener);

		return this;
	}

	/**
	 * Adds a listener to the collection for the specified event that will be called only once.
	 * @memberof! Emitter.prototype
	 * @function
	 * @param {String} event - The event name.
	 * @param {Function} listener - A listener function to add.
	 * @returns {Object} Returns an instance of Emitter.
	 * @example
	 * // Will add an event handler to "foo" event once.
	 * emitter.once('foo', listener);
	 */
	once(event, listener) {
		const self = this;

		function fn() {
			self.off(event, fn);
			listener.apply(this, arguments);
		}

		fn.listener = listener;

		this.on(event, fn);

		return this;
	}

	/**
	 * Removes a listener from the collection for the specified event.
	 * @memberof! Emitter.prototype
	 * @function
	 * @param {String} event - The event name.
	 * @param {Function} listener - A listener function to remove.
	 * @returns {Object} Returns an instance of Emitter.
	 * @example
	 * // Remove a given listener.
	 * emitter.off('foo', listener);
	 */
	off(event, listener) {
		let listeners;

		// Defines listeners value.
		if (
			!this._eventCollection ||
			!(listeners = this._eventCollection[event])
		) {
			return this;
		}

		listeners.forEach((fn, i) => {
			if (
				String(fn) === String(listener) ||
				String(fn.listener) === String(listener)
			) {
				// Removes the given listener.
				listeners.splice(i, 1);
			}
		});

		// Removes an empty event collection.
		if (listeners.length === 0) {
			delete this._eventCollection[event];
		}

		return this;
	}

	/**
	 * Execute each item in the listener collection in order with the specified data.
	 * @memberof! Emitter.prototype
	 * @function
	 * @param {String} event - The name of the event you want to emit.
	 * @param {...Object} data - Data to pass to the listeners.
	 * @returns {Object} Returns an instance of Emitter.
	 * @example
	 * // Emits the "foo" event with 'param1' and 'param2' as arguments.
	 * emitter.emit('foo', 'param1', 'param2');
	 */
	emit(event, ...args) {
		let listeners;

		// Defines listeners value.
		if (
			!this._eventCollection ||
			!(listeners = this._eventCollection[event])
		) {
			return this;
		}

		// Clone listeners
		listeners = listeners.slice(0);

		listeners.forEach((fn) => fn.apply(this, args));

		return this;
	}
}

/**
 * Exports Emitter
 */
export default Emitter;
